if (window.innerWidth < 640) {
    var searchResultsSortMenu = document.querySelector('.pp-search-results .pp-search-results__sort-menu');
    var searchResultsFilterMenu = document.querySelector('.pp-search-results .pp-search-results__filter-menu');
    var searchResultsFilter = document.querySelector('.pp-search-results .pp-search-results__filters');
    searchResultsFilterMenu.addEventListener('click', function(ev) {
        if(!searchResultsFilter.classList.contains('active')) {
            searchResultsFilter.classList.add('active');
        }

        var searchResultsFilterMenuClose = document.querySelector('.pp-search-results .pp-search-results__filters-header button');
        var submenuFilterHeading = document.querySelectorAll('.pp-search-results .pp-search-results__filter h4');

        searchResultsFilterMenuClose.addEventListener('click', function(ev) {
            searchResultsFilter.classList.remove('active');
        }, false);

        if(searchResultsFilter.classList.contains('active')) {
            for (var i = 0; i < submenuFilterHeading.length; i++) {
                submenuFilterHeading[i].addEventListener('click', function(ev) {
                    this.closest('.pp-search-results__filter').classList.toggle('activeSubmenu');
                }, false);
            }
        }
    }, false);
};

if (window.innerWidth > 640) {

    var searchPageFilter = document.querySelectorAll('.pp-search-results .pp-search-results__filters h4');
    var searchPage = document.querySelectorAll('.pp-search-results .pp-search-results__filter');

    for (var i = 0; i < searchPageFilter.length; i++) {
        searchPageFilter[i].addEventListener('click', function(ev) {
            this.closest('div').classList.toggle('active');

            var searchPage = document.querySelectorAll('.pp-search-results .pp-search-results__filter');
            var searchPageFilterButton = document.querySelectorAll('.pp-search-results .pp-search-results__filters li button');

            if(this.closest('div').classList.contains('active')) {
                for (var i = 0; i < searchPageFilterButton.length; i++) {
                    searchPageFilterButton[i].addEventListener('click', function(ev) {
                        this.closest('li').classList.toggle('activeSubmenu');
                    }, false);
                }
            }

        }, false);
    }
}




